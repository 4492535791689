import React from "react";

import MainPage from "../Components/MainPage"
import Navbar from '../Components/Navbar'
import Services from '../Components/Services'
import WhyUs from '../Components/WhyUs'
import ContactUs from '../Components/ContactUs'
import Footer from '../Components/Footer'
import ProductCards from "../Components/ProductCards";
import Testimonials from "../Components/Testimonials"
import AboutUs from "../Components/AboutUs"
import videoCallIcon from "../Assets/images/video-call.png"
import FBlogo from "../Assets/SVG/FBlogo"
// import ChatBot from '../Components/ChatBot';

function Home() {
  console.log('ClownDev')
  return(
    <div>
      {/* <a href='./meet' target="_blank" className="float">
        <img src={videoCallIcon}  className="my-float" alt="Logo" width="75" height="75"/>
      </a> */}
      <Navbar/>
      <MainPage/>
      {/* <ProductCards /> */}
      <Services/>
      <Testimonials />
      <AboutUs />
      <WhyUs/>
      <ContactUs/>
      <Footer/>
      {/* <ChatBot /> */}
    </div>
  )
  
}

export default Home;