import React from "react"
import WebDevelopment from "../Assets/SVG/WebDevelopment"
import IotDevelopment from "../Assets/SVG/IotDevelopment"
import ApiDevelopment from "../Assets/SVG/ApiDevelopment"
import marketing_icon from "../Assets/images/marketing.png"
import ai_icon from "../Assets/images/ai-icon.png"
import app_development_icon from "../Assets/images/app-development-icon.png"

const Services = () => {
    return (
        <>

        <header className="section-header">
            <p>Our services</p>
        </header>

        <section id="services" className="services">
            
            <div className="row container">
            {/*--------------------------------Cared------------------------------------*/}
            
                <div className="col-lg-4 card">
                    <div className="face face1">
                        <div className="content">
                            <div className="icon">
                                <WebDevelopment className="fa"></WebDevelopment>
                                <h3 className="services-label">Web Development</h3>
                            </div>
                        </div>
                    </div>
                    <div className="face face2">
                        <div className="content">
                            <h5>
                            We create stunning, mobile-friendly websites to elevate your business online.
                            </h5>
                        </div>
                    </div>
                </div>
                {/*--------------------------------Cared------------------------------------*/}
                <div className="col-lg-4 mt-4 mt-lg-0 card">
                    <div className="face face1">
                        <div className="content">
                            <div className="icon">
                                <img src={ai_icon} alt="AI/ML Icon" width="100" height="100" />
                                <h3 className="services-label">AI/ML</h3>
                            </div>
                        </div>
                    </div>
                    <div className="face face2">
                        <div className="content">
                            <h5>
                            Leverage AI-powered insights and automation to drive smarter business decisions.
                            </h5>
                        </div>
                    </div>
                </div>
                {/*--------------------------------Cared------------------------------------*/}
                <div className="col-lg-4 mt-4 mt-lg-0 card">
                        <div className="face face1">
                            <div className="content">
                                <div className="icon">
                                    <ApiDevelopment className="fa"></ApiDevelopment>
                                    <h3 className="services-label">API Development</h3>
                                </div>
                            </div>
                        </div>
                        <div className="face face2">
                            <div className="content">
                                <h5>
                                Seamless API solutions for effortless system integration and enhanced functionality.
                                </h5>
                            </div>
                        </div>
                    </div>
                {/*--------------------------------Cared------------------------------------*/}
                <div className="col-lg-4 mt-4 mt-lg-0 card">
                    <div className="face face1">
                        <div className="content">
                            <div className="icon">
                            <img src={app_development_icon} alt="App Development Icon" width="100" height="100" />
                                <h3 className="services-label">App Development</h3>
                            </div>
                        </div>
                    </div>
                    <div className="face face2">
                        <div className="content">
                            <h5>
                            Build high-performance mobile apps that deliver seamless user experiences.
                            </h5>
                        </div>
                    </div>
                </div>
                {/*--------------------------------Cared------------------------------------*/}
                <div className="col-lg-4 mt-4 mt-lg-0 card">
                    <div className="face face1">
                        <div className="content">
                            <div className="icon">
                                <img src={marketing_icon} alt="Digital Marketing Icon" width="100" height="100" />
                                <h3 className="services-label">Digital Marketing</h3>
                            </div>
                        </div>
                    </div>
                    <div className="face face2">
                        <div className="content">
                            <h5>
                            Maximize your reach with data-driven SEO, social media, and paid ads.
                            </h5>
                        </div>
                    </div>
                    </div>
                {/*--------------------------------Cared------------------------------------*/}

                <div className="col-lg-4 mt-4 mt-lg-0 card">
                    <div className="face face1">
                        <div className="content">
                            <div className="icon">
                                <IotDevelopment className="fa"></IotDevelopment>
                                <h3 className="services-label">IoT and Automation</h3>
                            </div>
                        </div>
                    </div>
                    <div className="face face2">
                        <div className="content">
                            <h5>
                            Automate tasks and connect devices for smarter, more efficient operations.
                            </h5>
                        </div>
                    </div>
                </div>

            </div>
        </section>
        </>
    )
}

export default Services;